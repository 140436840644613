@import "../../../public/mixins/mixins.scss";

.navbarWrapper {
  border-bottom: 1px solid #f1f1f1;
  position: sticky;
  top: 0px;
  background-color: #fff; // Add this to ensure the background is not transparent
  z-index: 1000; // Add this to ensure the navbar stays on top of other elements
  @include mobile() {
    border-bottom: none;
  }

  .navbar {
    @include flexContainer(row, space-between, center, 8px, 20px, wrap);
    padding: 8px 60px;
    max-width: 1440px;
    margin: auto;
    @include mobile() {
      padding: 14px 20px;
    }

    .logoContainer {
      order: 1;
      position: relative;
      width: 170px;
      height: 60px;
      @include mobile() {
        width: 110px;
        height: 30px;
      }
    }

    .navLinksContainer {
      order: 2;
      margin: auto;
      list-style: none;
      @include flexContainer(row, unset, unset, 0px, 20px);
      @include mobile() {
        order: 3;
        column-gap: 0px;
      }

      .links {
        text-decoration: none;
        @include font(18px, 500, #363636);
        @include mobile() {
          font-size: 16px;
        }
      }
      .active {
        @include font(18px, 700, #006daf);
        @include mobile() {
          font-size: 16px;
        }
        position: relative;
        &:after {
          @include underlineText(4px, #006daf);
          @include mobile() {
            @include underlineText(3px, #006daf);
          }
        }
      }
    }

    .becomePartnerHeading {
      order: 3;
      position: relative;
      cursor: pointer;
      @include font(14px, 700, #007a5a, 0.56px);
      @include mobile() {
        font-size: 12px;
        order: 2;
      }
      &:after {
        @include underlineText(2px, #007a5a);
      }
    }

    .whatsAppIcon {
      order: 3;
      cursor: pointer;
      @include font(14px, 700, #007a5a, 0.56px);
      @include mobile() {
        font-size: 12px;
        order: 3;
      }
      // &:after {
      //   @include underlineText(2px, #007a5a);
      // }
    }

    .telegramIcon {
      order: 3;
      cursor: pointer;
      @include font(14px, 700, #007a5a, 0.56px);
      @include mobile() {
        font-size: 12px;
        order: 2;
      }
      // &:after {
      //   @include underlineText(2px, #007a5a);
      // }
    }
  }
}
.links {
  text-decoration: none;
  @include font(18px, 500, #363636);
}
.active {
  @include font(18px, 700, #006daf);
  position: relative;
  &:after {
    @include underlineText(4px, #006daf);
  }
}
